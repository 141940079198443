import { startOfMonth, endOfMonth, eachDayOfInterval, format } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const roundDateMinutes = (date: Date, roundTo: number) => {
  const ms = 1000 * 60 * roundTo;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

export const getMonthDayList = (date?: Date | null): number[] => {
  const today = new Date();

  const start = startOfMonth(date || today);
  const end = endOfMonth(date || today);
  const daysArray = eachDayOfInterval({ start, end });

  return daysArray.map((day) => Number(format(day, "d")));
};

export const createUTCDate = (dateString: string): Date => {
  const [year, month, day, hour, minute, second] = dateString
    .split(/[-T:]/)
    .map(Number);

  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
};

export const convertDbDateToCenterTZ = ({
  dateString,
  timezone,
}: {
  dateString: string;
  timezone: string;
}) => {
  return utcToZonedTime(createUTCDate(dateString), timezone);
};
