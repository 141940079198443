import addMinutes from "date-fns/addMinutes";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import format from "date-fns/format";
import getDate from "date-fns/getDate";
import getMonth from "date-fns/getMonth";
import max from "date-fns/max";
import min from "date-fns/min";
import { OperatingHours, OperatingHoursDayKey } from "./types";

const endOfDayUtcMinutes = 60 * 24;

const getIntervalOperatingHours = (params: {
  startDate: Date;
  endDate: Date;
  operatingHours: OperatingHours;
}) => {
  const { startDate, endDate, operatingHours } = params;

  const eachDateOfInterval = eachDayOfInterval({
    start: startDate,
    end: endDate,
  });

  const intervalData = eachDateOfInterval
    .map((date) => {
      const dayKey = format(date, "i") as OperatingHoursDayKey;

      const dayOperatingHours = operatingHours[dayKey];

      if (!dayOperatingHours) return null;

      const startOfOperatingDayTimezone = addMinutes(
        date,
        dayOperatingHours?.fromMinutesAtUTC || 0,
      );

      const endOfOperatingDayTimezone = addMinutes(
        date,
        dayOperatingHours?.toMinutesAtUTC || endOfDayUtcMinutes,
      );

      const startLimited = max([startOfOperatingDayTimezone, startDate]);

      const endLimited = min([endOfOperatingDayTimezone, endDate]);

      return {
        day: getDate(startLimited),
        month: getMonth(startLimited),
        startOfOperatingDayTimezone: startLimited,
        endOfOperatingDayTimezone: endLimited,
      };
    })
    .filter(Boolean);

  return intervalData as NonNullable<typeof intervalData[number]>[];
};

export default getIntervalOperatingHours;
