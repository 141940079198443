/* eslint-disable @typescript-eslint/naming-convention */
import Big from "big.js";
import { DatabaseRow } from "@Shape-Digital/kudzu-data/lib/types/common";
import { SystemConfig } from "../../../hooks/useSystemConfig";
import { convertDbDateToCenterTZ } from "../../../common/dateHelpers";

type SupabaseCenterConfig = Pick<
  DatabaseRow<"center_configs">,
  | "discount_amount"
  | "default_currency_code"
  | "default_offering_duration_minutes"
  | "appointment_checkout_duration_seconds"
  | "appointment_checkout_max_repeat_count"
>;

type SupabaseCenterAddress = DatabaseRow<"center_addresses">;

type SupabaseCenter = Pick<
  DatabaseRow<"centers">,
  | "name"
  | "country"
  | "city"
  | "postal_code"
  | "region"
  | "phone_number"
  | "email"
  | "fax_number"
  | "timezone"
  | "state"
> & {
  center_configs: SupabaseCenterConfig[];
  center_addresses: SupabaseCenterAddress[];
};

type SupabaseCenterOffering = Pick<
  DatabaseRow<"center_offerings">,
  "name" | "description" | "deposit_amount" | "price" | "duration_minutes"
>;

type SupabaseAppointmentOffering = Pick<
  DatabaseRow<"appointment_offerings">,
  "side"
> & {
  center_offering: SupabaseCenterOffering;
};

type SupabaseCenterPackage = Pick<
  DatabaseRow<"center_packages">,
  "description" | "name"
>;

type SupabaseAppointmentPackage = {
  center_package: SupabaseCenterPackage;
  appointment_offerings: SupabaseAppointmentOffering[];
};

type SupabaseCenterAddOn = Pick<
  DatabaseRow<"center_add_ons">,
  "price" | "name"
>;

type SupabaseAppointmentAddOn = {
  center_add_on: SupabaseCenterAddOn;
};

type SupabaseTimeSlot = Pick<DatabaseRow<"center_time_slots">, "started_at">;

type SupabaseAppointmentCustomerDetails = Pick<
  DatabaseRow<"appointment_customer_details">,
  "first_name" | "last_name" | "email" | "type"
>;

type SupabaseAppointmentPatientDetails = Pick<
  DatabaseRow<"appointment_patient_details">,
  "first_name" | "last_name" | "date_birth" | "email" | "mobile_phone_number"
>;

export type SupabaseConfirmedAppointment = Pick<
  DatabaseRow<"appointments">,
  "checkout_repeat_count" | "status"
> & {
  center: SupabaseCenter;
  appointment_offerings: SupabaseAppointmentOffering[];
  appointment_packages: SupabaseAppointmentPackage[];
  appointment_add_ons: SupabaseAppointmentAddOn[];
  time_slot: SupabaseTimeSlot;
  appointment_customer_details: SupabaseAppointmentCustomerDetails[];
  appointment_patient_details: SupabaseAppointmentPatientDetails[];
};

export const supabaseConfirmedAppointmentRequest = `
checkout_repeat_count,
status,
center:center_id (
  name,
  country,
  city,
  postal_code,
  region,
  phone_number,
  email,
  state,
  fax_number,
  timezone,
  center_configs (
    discount_amount,
    default_currency_code,
    default_offering_duration_minutes,
    appointment_checkout_duration_seconds,
    appointment_checkout_max_repeat_count
  ),
  center_addresses (
    address,
    type
  )
),
appointment_offerings (
  side,
  center_offering:center_offering_id (
    name,
    description,
    deposit_amount,
    price,
    duration_minutes
  )
),
appointment_packages (
  center_package:center_package_id (
    name,
    description
  ),
  appointment_offerings (
    side,
    center_offering:center_offering_id (
      name,
      description,
      deposit_amount,
      price,
      duration_minutes
    )
  )
),
appointment_add_ons (
  center_add_on:center_add_on_id (
    name,
    price
  )
),
time_slot:time_slot_id (
  started_at
),
appointment_customer_details (
  first_name,
  last_name,
  email
),
appointment_patient_details (
  first_name,
  last_name,
  date_birth,
  email,
  mobile_phone_number
)
`;

export type AppointmentOffering = {
  name: string;
  description: string;
  depositAmount: number;
  price: number;
  discountPrice: number;
  durationMinutes: number;
  side?: string;
};

export type AppointmentPackage = {
  name: string;
  description: string;
  depositAmount: number;
  price: number;
  durationMinutes: number;
  discountPrice: number;
  offerings: AppointmentOffering[];
};

export type AppointmentAddOn = {
  name: string;
  price: number;
};

export type Center = {
  name: string;
  country: string;
  city: string;
  postalCode: string;
  region: string;
  state: string;
  phoneNumber: string;
  email: string;
  faxNumber: string | null;
  discountAmount: number;
  defaultCurrencyCode: string;
  appointmentCheckoutDurationSeconds: number;
  appointmentCheckoutMaxRepeatCount: number;
  addresses: DatabaseRow<"center_addresses">[];
};

export type AppointmentTimeSlot = {
  startedAt: Date;
};

type AppointmentCustomerDetails = {
  firstName: string;
  lastName: string;
  email: string | null;
  type: SupabaseAppointmentCustomerDetails["type"];
};

type AppointmentPatientDetails = {
  firstName: string;
  lastName: string;
  dateBirth: string;
  email: string | null;
  mobilePhoneNumber: string | null;
};

const parseOffering = (params: {
  centerOffering: SupabaseCenterOffering;
  defaultOfferingDurationMinutes: number;
  discountAmount: number;
  side?: string;
}): AppointmentOffering => {
  const {
    centerOffering,
    defaultOfferingDurationMinutes,
    discountAmount,
    side,
  } = params;

  const { name, description, deposit_amount, price, duration_minutes } =
    centerOffering;

  const discountPrice = discountAmount
    ? Big(price).minus(discountAmount).toNumber()
    : price;

  return {
    name,
    description,
    depositAmount: deposit_amount,
    price,
    discountPrice,
    durationMinutes: duration_minutes ?? defaultOfferingDurationMinutes,
    side,
  };
};

const calcPackageSums = (offerings: AppointmentOffering[]) => {
  const initialValues = {
    depositAmount: Big(0),
    price: Big(0),
    durationMinutes: Big(0),
  };

  const bigResults = offerings.reduce((acc, offering) => {
    return {
      depositAmount: acc.depositAmount.plus(offering.depositAmount),
      price: acc.price.plus(offering.price),
      durationMinutes: acc.durationMinutes.plus(offering.durationMinutes),
    };
  }, initialValues);

  return {
    depositAmount: bigResults.depositAmount.round(2).toNumber(),
    price: bigResults.price.round(2).toNumber(),
    durationMinutes: bigResults.durationMinutes.round(2).toNumber(),
  };
};

export type ConfirmedAppointment = {
  checkoutRepeatCount: number;
  status: SupabaseConfirmedAppointment["status"];
  center: Center;
  offerings: AppointmentOffering[];
  packages: AppointmentPackage[];
  addOns: AppointmentAddOn[];
  timeSlot: AppointmentTimeSlot;
  customerDetails: AppointmentCustomerDetails;
  patientDetails: AppointmentPatientDetails;
};

const parseConfirmedAppointment = (
  supabaseAppointment: SupabaseConfirmedAppointment,
  systemConfig: SystemConfig,
): ConfirmedAppointment => {
  const {
    checkout_repeat_count,
    status,
    center,
    appointment_offerings,
    appointment_packages,
    appointment_add_ons,
    time_slot,
    appointment_customer_details,
    appointment_patient_details,
  } = supabaseAppointment;

  const {
    name,
    country,
    city,
    postal_code,
    region,
    state,
    phone_number,
    fax_number,
    email,
    center_configs,
    timezone,
    center_addresses,
  } = center;

  const appointmentCenter: Center = {
    name,
    country,
    city,
    postalCode: postal_code,
    region,
    addresses: center_addresses,
    state,
    phoneNumber: phone_number,
    faxNumber: fax_number,
    email,
    discountAmount:
      center_configs[0]?.discount_amount ?? systemConfig.discountAmount,

    defaultCurrencyCode:
      center_configs[0]?.default_currency_code ??
      systemConfig.defaultCurrencyCode,

    appointmentCheckoutDurationSeconds:
      center_configs[0]?.appointment_checkout_duration_seconds ??
      systemConfig.appointmentCheckoutDurationSeconds,

    appointmentCheckoutMaxRepeatCount:
      center_configs[0]?.appointment_checkout_max_repeat_count ??
      systemConfig.appointmentCheckoutMaxRepeatCount,
  };

  const defaultOfferingDurationMinutes =
    center_configs[0]?.default_offering_duration_minutes ??
    systemConfig.defaultOfferingDurationMinutes;

  const appointmentOfferings = appointment_offerings.map((offering) => {
    return parseOffering({
      centerOffering: offering.center_offering,
      defaultOfferingDurationMinutes,
      discountAmount: appointmentCenter.discountAmount,
      side: offering.side || undefined,
    });
  });

  const appointmentPackages = appointment_packages.map<AppointmentPackage>(
    (packageItem) => {
      const { center_package, appointment_offerings: packageOfferings } =
        packageItem;

      const { name: packageName, description } = center_package;

      const offerings = packageOfferings.map((offering) => {
        return parseOffering({
          centerOffering: offering.center_offering,
          defaultOfferingDurationMinutes,
          discountAmount: appointmentCenter.discountAmount,
        });
      });

      const { depositAmount, price, durationMinutes } =
        calcPackageSums(offerings);

      const discountPrice = appointmentCenter.discountAmount
        ? Big(price).minus(appointmentCenter.discountAmount).toNumber()
        : price;

      return {
        name: packageName,
        description,
        offerings,
        depositAmount,
        price,
        discountPrice,
        durationMinutes,
      };
    },
  );

  const appointmentAddOns = appointment_add_ons.map((addOn) => {
    const { name: addOnName, price } = addOn.center_add_on;
    return { name: addOnName, price };
  });

  const appointmentTimeSlot: AppointmentTimeSlot = {
    startedAt: convertDbDateToCenterTZ({
      dateString: time_slot.started_at,
      timezone,
    }),
  };

  const supabaseCustomerDetails = appointment_customer_details[0];

  if (!supabaseCustomerDetails) {
    throw new Error("Customer details is not defined");
  }

  const customerDetails: AppointmentCustomerDetails = {
    firstName: supabaseCustomerDetails.first_name,
    lastName: supabaseCustomerDetails.last_name,
    email: supabaseCustomerDetails.email,
    type: supabaseCustomerDetails.type,
  };

  const supabasePatientDetails = appointment_patient_details[0];

  if (!supabasePatientDetails) {
    throw new Error("Patient details is not defined");
  }

  const patientDetails: AppointmentPatientDetails = {
    firstName: supabasePatientDetails.first_name,
    lastName: supabasePatientDetails.last_name,
    dateBirth: supabasePatientDetails.date_birth,
    email: supabasePatientDetails.email,
    mobilePhoneNumber: supabasePatientDetails.mobile_phone_number,
  };

  return {
    checkoutRepeatCount: checkout_repeat_count || 0,
    status,
    center: appointmentCenter,
    offerings: appointmentOfferings,
    packages: appointmentPackages,
    addOns: appointmentAddOns,
    timeSlot: appointmentTimeSlot,
    customerDetails,
    patientDetails,
  };
};

export default parseConfirmedAppointment;
